@font-face {
  font-family: "Race Sport";
  src: url("../../fonts/Race_Sport_Free_Regular.ttf") format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Alien Robot";
  src: url("../../fonts/Alien_Robot_Regular.ttf") format("TrueType"),
    url("../../fonts/Alien_Robot_Regular.otf") format("OpenType");
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/Century_Gothic_Regular.ttf") format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icons.eot?vil6bf");
  src: url("../../fonts/icons.eot?vil6bf#iefix") format("embedded-opentype"),
    url("../../fonts/icons.ttf?vil6bf") format("truetype"),
    url("../../fonts/icons.woff?vil6bf") format("woff"),
    url("../../fonts/icons.svg?vil6bf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$ff-race-sport: "Race Sport", sans-serif;
$ff-alien-robot: "Alien Robot", sans-serif;
$ff-century-gothic: "Century Gothic", sans-serif;
