body {
  font-family: $ff-century-gothic;
  // font-size: ;
  // color: $clr-;
  background-color: $clr-red-485;
  font-weight: 400;
  line-height: 1;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}